.what {
    overflow: hidden;
    padding-bottom: 20px;
    @media screen(lg) {
        margin-top: 100px;
    }


}
.what-title {
    font-weight: 900;
    font-size: 24px;
}
.what-title span {
    color: #EA405C;
}
.what-description {
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    color: #626262;
    @media(max-width: 576px) {
        & {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.what-card__description {
    max-width: 280px;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #333333;
    p {
        margin: 0;
        padding-top: 35px;
        padding-bottom: 36px;
    }
}

.what-card_container {
    display: flex;
    gap: 20px;
    align-items: stretch;
    flex-direction: column;
    margin-top: 40px;
    @media screen(lg) {
        & {
            flex-direction: row;
            margin-top: 70px;
        }
    }
}

.what-card {
    flex-basis: 33%;
    padding-top: 60px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.06));
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    @media screen(md) {
        & {
            display: flex;
            flex-direction: row;
            flex-basis: 100%;
            width: 100%;
            gap: 50px;
            padding: 50px;

        }
    }
    @media screen(lg) {
        flex-direction: column;
        flex-basis: 33%;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0px;
        padding: 60px 40px 10px 40px;

    }
    @media (min-width: 320px) and (max-width: 480px) {
        & {
            padding-top: 50px;
            width: 100%;
            overflow: hidden;
            height: 100%;
        }
        .what-card__img {
            height: 100%;
            text-align: center;
            padding: 0;
            margin: 0;
        }
        .what-card__description {
            padding-top: 20px;
            padding-bottom: 50px;
            width: 250px;
        }
        .what-card__description p {
            height: 60%;
            padding: 0;
            margin: 0;
        }

    }
}


