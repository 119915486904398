:root {
    --swiper-navigation-size: 20px;
}

.swiper {
    width: 90%;
    @media screen(md) {
        width: 80%;
    }
    @media screen(lg) {
        width: 100%;
    }
}


.swiper-pagination-bullet {
    background: transparent;
    display: inline-flex;
    width: 1em;
    height: 30px;
    transition: width 0.4s;
    span {
        display: block;
        background: red;
        border-bottom: 2px red;
        width: 1em;
        height: 2px;
        border-radius: 0;

    }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 3em;
    span {
        display: block;
        background: red;
        border-bottom: 2px red;
        width: 3em;
        border-radius: 0;
    }


}

.functional-slider {
    overflow: visible;
    /* Тень плашки */
    .swiper-slide {
        border: 1px solid #CCCCCC;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.06));
        border-radius: 10px;
        overflow: hidden;
        width: 90%;
        transition: all 0.4s;
        transform: scale(1);
    }
    .swiper-pagination {
        position: unset;
        padding-top: 20px;
        @media screen(lg) {
            padding-top: 67px;
        }
    }
    

    picture {
        width: 100%;
    }

    img {
        width: 100%;
    }

    .swiper-slide-next {
        /* margin-top: 50px;
        margin-right: 20px; */
        transform: scale(1);
        z-index: 100;

        @media screen(lg) {
            transform: scale(1.3);
        }
    }
    

}


.swiperServiceContainer {
    max-width: 100%;
    overflow:hidden;
    padding-top: 0;
    @media screen(lg) {
        padding-top: 30px;
    }
}

.swiperService {
    width: 100%;
    position: relative;
    overflow: visible;
    .swiper-slide {
        text-align: center;
        /* height: 100%; */
        opacity: 0;
        transition: opacity 0.3s;
    }
    .swiper-slide-active {
        opacity: 1;
    }

    .swiper-slide__container {
        border: 0.5px solid rgba(234, 64, 92, 0.1);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 20px 24px 20px 24px;
        position: relative;
        /* height: 308px; */
        @media screen(md) {
            padding: 47px 40px 50px 40px;
            /* height: 345px; */
        }
        @media screen(lg) {
            padding: 47px 50px 50px 60px;
            /* height: 275px; */
        }
        .swiper-title {
            text-align: left;
            font-size: 24px;
            line-height: 36px;
            margin: 0;
            @media screen(md) {
                font-size: 36px;
                line-height: 44px;

            }
            @media screen(lg) {
                margin-bottom: 25px;
            }
        
        }
    }

    
    
    .swiper-block {
        display: flex;
        flex-basis: 100%;
        flex-direction: column;
        @media screen(lg) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    
    .swiper-content {
        text-align: left;
        flex-basis: 50%;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        b {
            font-weight: 500;
            color: #333333;
        }
        @media screen(md) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    
    .swiper-button {
        all: unset;
        /* flex-basis: 40%; */
        
        cursor: pointer;
        border-radius: 10px;
        background: linear-gradient(91.55deg, #EA405C 33.51%, #FFFFFF 224.71%);

        color: #FFFFFF;
        font-size: 12px;
        line-height: 14px;
        font-weight: 900;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        height: 50px;
        &:hover {
            background: #EA405C;
        }
        width: 90%;
        @media screen(md) {
            height: 66px;
            font-size: 18px;
            line-height: 36px;
        }
        @media screen(lg) {
            flex-basis: 40%;
        }
    }
    
    .swiper-button i {
        width: 5px;
        height: 5px;
    }
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
        opacity: 0;
    }
    .swiper-button-next, .swiper-button-prev {
        color: #626262;
        transition: 0.1s ease-in color;
        display: none;
        transform: translateY(-20px);
        @media screen(lg) {
            display: block;
        }
    }
    .swiper-button-next:hover, .swiper-button-prev:hover {
        color: red;
    }
    .swiper-button-next {
       position: absolute;
       right: -40px;
       z-index: 1;
    }
    .swiper-button-prev {
       position: absolute;
       left: -40px;
       z-index: 1;
    }
    
    .swiper-pagination {
        position: unset;
        margin-top: 10px;
        /* @media screen(md) {
            margin-top: -20px;
        } */
        @media screen(lg) {
            margin-top: 30px;
        }
    }
}




/* .swiper-class-active {
    margin-top: 50px;
    margin-right: 20px;
    transform: scale(1.3);
    z-index: 100;`
} */