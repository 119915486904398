.functional-section {
    overflow: hidden;
    h2 {
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 15px;
        @media screen(md) {
            padding-bottom: 30px;
            font-size: 36px;
            line-height: 44px;
        }
        @media screen(lg) {
            padding-bottom: 65px;
        }
    }
    padding: 35px 0;
    @media screen(lg) {
        padding: 65px 0;
    }
    picture {
        user-select: none;
    }
}