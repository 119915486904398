@import "./tailwind.pcss";

@import "./core.pcss";
@import "./swiper.pcss";

@import "./index.pcss";
@import "./header.pcss";
@import "./what.pcss";
@import "./partners.pcss";
@import "./functional.pcss";
@import "./modal.pcss";
@import "information.pcss";
@import "education.pcss";
@import "./footer.pcss";
@import "./404.pcss";