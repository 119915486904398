/* HERO */

.hero-block {
  width: 100%;
  height: 424px;
  position: relative;
  @media screen(md) {
    height: 655px;
  }
  @media screen(lg) {
    height: 570px;
  }
}
.hero-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.hero-img-gradient {
  display: none;
  background: linear-gradient(
    74.64deg,
    #000000 -13.03%,
    rgba(0, 0, 0, 0) 63.94%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media screen(lg) {
    display: block;
  }
}
.hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  display: block;
  position: relative;
  top: 40%;
  @media screen(md) {
    height: 655px;
    top: 45%;
  }
  @media screen(lg) {
    height: 570px;
    top: 20%;
  }
}

.hero-title h1 {
  margin: 0;
  padding: 0;
  font-size: 72px;
  color: #333333;
  font-weight: 900;
  @media screen(md) {
    font-size: 120px;
  }
  @media screen(lg) {
    font-size: 144px;
    color: #ffffff;
  }
}

.hero-title h2 {
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-size: 20px;
  color: #333333;
  text-align: left;
  margin-top: -30px;
  @media screen(md) {
    font-size: 36px;
    margin-top: -30px;
  }
  @media screen(lg) {
    font-size: 48px;
    color: #ffffff;
    margin-top: -35px;
  }
}
.hero-description {
  margin-top: 10px;
  color: #333333; 
  font-size: 12px;
  font-weight: 400;
  @media screen(md) {
    font-size: 14px; 
    margin-top: 28px;
  }
  @media screen(lg) {
    font-size: 18px; 
    color: #ffffff;
    margin-top: 32px;
  }
  @media (max-width: 576px) {
    & {
      font-size: 12px;
    }
  }
}

.flip-container-container {
  padding: 40px 0;
  margin-top: calc(-50px - 40px);
  overflow: hidden;
}

.flip-container {
  @apply basis-1/2 
    justify-center
    overflow-visible;
}
.flip-card {
  cursor: pointer;
  display: inline-block;
  /* margin-left: 30px; */
  background-color: transparent;
  max-width: 220px;
  height: 220px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.06));
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 10px;
}
.flip-card-back h4 {
  text-align: left;
  padding: 0 17px;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;

  height: 65%;
}
.flip-card-back a {
  position: absolute;
  display: block;
  position: relative;
  margin-left: 60px;
  color: #ffffff;
}
/* Style the front side (fallback if image is missing) */
.flip-card-front {
  padding-left: 20px;
  background-color: #ffffff;
  color: #0a0203;
  text-align: left;
}
.flip-card-front_img {
  position: relative;
  top: 50px;
  left: 120px;
}
.flip-card-front span {
  color: #ea405c;
}

/* Style the back side */
.flip-card-back {
  background-color: #f490a1;
  color: #ffffff;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  transform: rotateY(180deg);
  background: linear-gradient(136.68deg, #ea405c 27.01%, #f4bbc4 190.6%);
}
.flip-card-back a {
  font-weight: 900;
  font-size: 14px;
  color: #ffffff;
}
.flip-card-back h4 b {
  font-weight: 900;
}

.form {
  overflow: hidden;
  position: relative;
  margin-top: 2em;
  @media screen(md) {
    margin-top: 2em;
  }

  .form-text_img {
    position: absolute;
    z-index: -1;
    top: 1%;
    right: -3%;
    width: 230px;
    transform: scaleX(-1);
    img {
      height: 230px;
    }
    @media screen(md) {
      width: 100%;
      top: 4%;
      img {
        height: 350px;

      }
    }
    @media screen(lg) {
      position: absolute;
      top: 0;
      left: -25px;
      transform: scaleX(1);
      img {
        height: auto;
      }
    }
  }
  .form-container {
    display: flex;
    flex-direction: column;
    @media screen(lg) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      position: relative;
    }

    .form-text {
      margin-top: 30px;
      width: 60%;
      @media screen(md) {
        margin-top: 100px;
        width: 50%;
        /* position: absolute; */
      }
      @media screen(lg) {
        margin-top: 200px;
        width: 30%;
        margin-left: 80px;
        /* position: absolute; */
        justify-self: center;
      }
      span {
        color: #ea405c;
      }
      p {
        color: #626262;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
        @media (max-width: 576px) {
          & {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      .form-text_container {
        h2 {
          line-height: 36px;
          @media screen(md) {
            line-height: 44px;
          }

        }
        @media screen(lg) {
        }
      }
    }

    .form-block {
      display: flex;
      flex-direction: column;
      width: 100%;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.06));
      border-radius: 10px;
      padding: 25px 26px 23px 22px;
      margin-top: 50px;
      @media screen(md) {
        width: auto;
        /* max-width: 500px; */
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.06));
        border-radius: 10px;
        padding: 45px 55px 40px 55px;
        margin-top: 80px;
      }
      @media screen(lg) {
        max-width: 500px;
      }
    }
  }
}

.form-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form-input span {
  font-weight: 300;
  font-size: 12px;
  color: #333333;
  @media screen(md) {
    font-size: 18px;
  }
}
.form-input input {
  margin-bottom: 25px;
  border: 1px solid rgba(98, 98, 98, 0.3);
  border-radius: 10px;
  padding: 10px;
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  @media screen(md) {
    font-size: 18px;
  }
}

.form-input input::placeholder {
  color: #9e9e9e;
  font-size: 12px;
  font-weight: 300;
  @media screen(md) {
    font-size: 18px;
  }
}

.form-button {
  width: 100%;
  background: linear-gradient(91.55deg, #EA405C 33.51%, #FFFFFF 224.71%);
  color: #ffffff;
  border-radius: 10px;
  border: none;
  padding: 15px 65px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 12px;
  @media screen(md) {
    font-size: 18px;
    padding: 20px 65px;
  }
  &:hover {
    background: #EA405C;
  }
}
.form-checkbox {
  display: inline-flex;
  /* justify-content: center; */
  align-items: center;
  /* @media screen(md) {
    align-items: flex-start;
  } */
  gap: 10px;
}
.form-checkbox span {
  font-weight: 300;
  font-size: 8px;
  color: #626262;
  @media screen(md) {
    font-size: 12px;
  }
  a {
    transition: color 0.3s linear;
  }
  a:hover {
    color: #EA405C;
  }
}
.checkbox {
  --background: #fff;
  --border: #d1d6ee;
  --border-hover: #bbc1e1;
  --border-active: red;
  --tick: #fff;
  position: relative;
  input,
  svg {
    width: 21px;
    height: 21px;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: var(--background);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
    &:hover {
      --s: 2px;
      --b: var(--border-hover);
    }
    &:checked {
      --b: var(--border-active);
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border-active));
    position: absolute;
    top: 0;
    left: 0;
    width: 21px;
    height: 21px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }
  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: 0.4s;
        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }
    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }
  &.bounce {
    --stroke: var(--tick);
    input {
      &:checked {
        --s: 11px;
        & + svg {
          animation: bounce 0.4s linear forwards 0.2s;
        }
      }
    }
    svg {
      --scale: 0;
    }
  }
}
