
  
  .error-body {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .error-main {

    padding-top: 104px;
    @media screen(lg) {
        padding-top: 260px;
    }
  }

  .error-footer {
    margin-top: auto;
  }
  
.error-container {

    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 80vh; */
    margin: 0 auto;
    flex-direction: column;
    @media screen(lg) {
        flex-direction: row;
        gap: 135px;
    }
    .error-icons {
        margin-bottom: 30px;
        width: 200px;
        margin-right: 40px;

        @media screen(md) {
            width: 300px;
            margin-bottom: 45px;
        }
        @media screen(lg) {
            margin: 0;
            width: 400px;
        }
    }
    .error-title {
        font-weight: 900;
        color: #EA405C;
        font-size: 114px;
        line-height: 114px;
        margin-bottom: 20px;
        @media screen(md) {
            font-size: 150px;
            line-height: 144px;
            margin-bottom: 30px;
        }
        @media screen(lg) {
            margin-bottom: 25px;
        }
    }
    .error-link a {
        text-decoration: underline;
        color: #EA405C;
    }
    .error-description {
        padding-bottom: 22px;
        @media screen(md) { 
            padding-bottom: 30px;
        }
    }
    span {
        color: #1F2228;
        font-weight: 400;
        letter-spacing: -0.02em;
        font-size: 14px;
        @media screen(md) { 
            font-size: 18px;
        }
    }
}