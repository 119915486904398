
.information {
	margin-top: 30px;
	margin-bottom: 24px;
	@media screen(md) {
		margin-top: 72px;
		margin-bottom: 24px;
	}
	@media screen(lg) {
		padding-top: 50px;
		margin-bottom: 24px;
	}
	.information-title {
		@media screen(lg) {
			br {
				display: none;
			}
		}
	}
	.information-description {
		padding-bottom: 12px;
		font-size: 16px;
		line-height: 24px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 900;
		@media screen(md) {

			font-size: 20px;
			line-height: 44px;
			/* identical to box height, or 220% */
			color: #333333;
			padding-bottom: 14px;
		}

		display: block;
	}
	.information-desktop {
		background: #FFFFFF;
		border: 0.5px solid #FCD1D8;
		/* Тень плашки */
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
		border-radius: 10px;
		box-sizing: border-box;
		
		flex-direction: column;
		padding: 38px 30px 42px;
		flex-wrap: wrap;
		flex-direction: row;
		display: none;
		@media screen(lg) {
			display: flex;
		}
		.information-desktop__one-col {
			flex-basis: 50%;
			width: 50%;
			display: flex;
			flex-direction: column;
			gap: 0px;
			.information-desktop__column:last-child {
				border: none;
				padding-bottom: 0;
			}
			.information-desktop__column.padding-bottom {
				padding-bottom: 60px;

			}
			.information-desktop__column:first-child {
				padding-top: 0;
			}
			.information-desktop__column {
				border-bottom: 1px solid #CDCDCD;
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding-bottom: 24px;
				padding-top: 24px;
				width: 90%;
	
				.title {
					padding-left: 5px;
					display: flex;
					gap: 10px;
					width: 231px;
					min-height: 60px;
					.dot {
						margin-top: 6px;
						min-width: 3px;
						height: 3px;
						background-color: #333333;
						border-radius: 50%;
					}
					span {
						font-family: 'Roboto';
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 18px;
					}
				}
				.description {
					flex-basis: 42%;
					span {
						font-family: 'Roboto';
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 18px;
						/* or 129% */
						color: #EA405C;
					}
	
				}
			}
		}
		


	}
	.information-main {
		background: #FFFFFF;
		border: 0.5px solid #FCD1D8;
		/* Тень плашки */
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
		border-radius: 10px;
		padding: 17px 12px 24px;
		@media screen(md) {
			padding: 38px 38px 36px;
		}
		@media screen(lg) {
			display: none;
		}
		.information-main__column:first-child {
			padding-top: 0px;
		}
		.information-main__column:last-child {
			border-bottom: 0px;
		}
		.information-main__column {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding-bottom: 24px;
			padding-top: 24px;
			border-bottom: 1px solid #CDCDCD;
			@media screen(md){
				flex-direction: row;
				justify-content: space-between;
			}

			
			.title {
				display: flex;
				padding-left: 6px;
				gap: 10px;
				span {
					
					font-family: 'Roboto';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 18px;
					/* or 129% */
					color: #333333;
					max-width: 90%;
					@media screen(md){
						max-width: 231px;
					}
				}
				.dot {
					margin-top: 5px;
					width: 5px;
					height: 5px;
					background-color: black;
					border-radius: 50%;
				}
			}
			.description {
				display: flex;
				flex-basis: 50%;
				padding-left: 22px;
				@media screen(lg) {
					flex-basis: 100px;
				}
				span {
					font-family: 'Roboto';
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					/* or 129% */
					color: #EA405C;
				}
			}
		}

	}
}

