.partner-services {

    margin-top: 40px;
    @media screen(md) {
      margin-top: 80px;
    }
    @media screen(lg) {
      margin-top: 120px;
    }
}
.partner-services_container {
    margin-top: 35px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: start;
    grid-gap: 50px;
    margin-left: 20px;
    @media screen(md) {
      grid-gap: 100px;
      margin-top: 92px;
    }
    @media screen(lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .partner-services_block {
    display: grid;

  }
  .partner-services_block_container {
    display: grid;
    justify-content: center;
    align-items: center;
    max-width: 70%;
    @media screen(md) {
      max-width: 50%;
    }
  }
  .partner-services_img {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #EA405C;
  }
  .partner-services_text {
      text-align: left;
      margin-top: 20px;
      line-height: 20px;
      font-weight: 300;
      font-size: 12px; 
      @media screen(md) {
        font-size: 14px;
        line-height: 24px;
      }
      & ul > li {
          list-style-type: disc;
          color: #EA405C;
          padding-top: 5px;
          padding-left: 5px;
      }
      & ul > li > span {
        color: #333333;
        line-height: 20px;
        font-size: 12px;
        @media screen(md) {
          font-size: 14px;
          line-height: 24px;
        }
      }
  }

  .partner-services-title {

      h2 {
          line-height: 36px;
          padding: 0;
          margin: 0;
          @media screen(md) {
            line-height: 44px;
          }
      }
      p {
        max-width: 420px;
        font-weight: 500;
        color: #626262;
        line-height: 24px;
        padding-top: 20px;
        margin: 0;
        font-size: 14px;
        @media screen(md) {
          font-size: 18px;
          line-height: 36px;
          padding-top: 40px;
        }
      }
  }