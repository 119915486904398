.footer-container {
    display: none;
    @media screen(md) {
        display: flex;
        margin-top: 80px;
        margin-bottom: 50px;
    
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        color: #333333;
    }
}
.footer-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 300;
    font-size: 12px;
    a {
        &:hover {
            color: #EA405C;
        }
    }
    a, span {
        font-weight: 300;
        font-size: 12px;

        @media screen(lg) {
            font-size: 14px;
        }
    }
}


.footer-column_social {
    @apply p-2 basis-full order-3;
    @apply flex flex-row justify-center items-center;

    @media screen(md) {
        gap: 15px;
        margin-top: 25px;
    }
    /* Bigger than */
    @media screen(lg) {
        @apply mt-10 basis-auto order-none;
        gap: 10px;
    }

    a {
        @apply mr-2 block;
    }    
    button {
        @apply mr-2
    }
}

.footer-underline  {
    border-bottom: 1px solid black ;
    width: fit-content;
    &:hover {
        border-color: #EA405C;
    }
}

.footer-social_icons:last-child { 
    margin: 0;
}

.footer-logo {
    width: 50px;
    height: 50px;
    @media screen(lg) {
        width: 60px;
        height: 60px;
    }
}



.footer-container-mobile {
    display: flex;

    img {
        width: 50px;
        height: 50px;
    }
    margin-bottom: 15px;
    margin-top: 40px;
    .footer-mobile-link {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    @media screen(md) {
        display: none;
    }
}
.footer-mobile_column {
    display: flex;
    font-size: 12px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.footer-mobile-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer-mobile-text {
    font-weight: 300;
    font-size: 12px;
    color: #333333;
    .footer-mobile-link_margin {
        padding-right: 10px;
    }
}
.footer-mobile_underline {
    font-weight: 300;
    font-size: 12px;
    line-height: 36px;
    text-decoration-line: underline;
    color: #333333;
}

.footer-icon-mobile {
    @apply flex flex-row justify-end items-center;
    margin-bottom: 30px;
    margin-right: 28px;
    gap: 10px;
    @media screen(md) {
        display: none;
    }
    a {
        @apply mr-2 block;
    }    
}