header {
    padding: 10px 0;
}
.menu {
    display: flex;
    justify-content: space-between;
    /* @media screen(md) {
        padding: 0px 40px;
    } */
}

.menu-link {
    display: flex;
    align-items: center;
    gap: 30px;

}
.menu-link img {
    width: 30px;
    height: 30px;
    @media screen(md) {
        width: 40px;
        height: 40px;
    }
    @media screen(lg) {
        width: 60px;
        height: 60px;
      }
}
.menu-link a {
    font-size: 12px;
    color: #626262;
    display: none;
    font-weight: 300;
    transition: all 0.1s ease-in color;
    @media screen(md) {
        display: block;
        font-size: 14px;
    } 
    @media screen(lg) {
        font-size: 18px;
    }
    &:hover {
        color: #EA405C;
        text-shadow: 0 0 .9px #EA405C, 0 0 .9px  #EA405C, 0 0 .9px #EA405C;
    }
}

.menu-phone {
    display: flex;
    align-items: center;
    gap: 7px;
    @media screen(lg) {
        gap: 10px;
    }

}
.menu-phone span {
    display: none;
    @media screen(md) {
        display: block;
    }
}


.menu-phone a {
    font-weight: 600;
    color: #333333;
    transition: 0.1s ease-in color;
    @media screen(md) {
        font-size: 14px;
    }
    @media screen(lg) {
        font-size: 18px;
    }
    img {
        width: 20px;
        height: 20px;
        @media screen(md) {
            width: 13px;
            height: 13px;
        }
        @media screen(lg) {
            width: 20px;
            height: 20px;
        }

    }
    &:hover {
        color: #EA405C;
    }
}