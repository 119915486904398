.education {
	.education-title {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 900;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 220% */
		color: #333333;
		padding-bottom: 12px;
		h4 {
			padding: 0;
			margin: 0;
		}
		@media screen(md) {
			padding-bottom: 14px;
			font-size: 20px;
			line-height: 44px;
		}
	}
	.education-container {
		display: flex;
		gap: 17px;
		flex-direction: column;
		@media screen(lg) {
			flex-direction: row;
			gap: 20px;
		}
		.education__col {
			justify-content: space-between;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
			border-radius: 10px;
			display: flex;
			box-sizing: border-box;
			background: #FFFFFF;
			border: 0.5px solid #FCD1D8;
			flex-direction: column;
			flex-basis: 100%;
			padding-top: 12px;
			padding-left: 12px;
			padding-right: 12px;
			padding-bottom: 3px;
			@media screen(md) {
				min-height: 144px;
				padding-top: 24px;
				padding-bottom: 15px;
				padding-right: 22px;
				padding-left: 22px;
			}
			@media screen(lg) {			
				flex-basis: 50%;
				padding-left: 26px;
				padding-right: 26px;
			}
			.education__col-info {
				display: flex;
				gap: 14px;
				@media screen(md) {
					gap: 18px;
				}
				.icons {
					img {
						min-width: 32px;
						min-height: 32px;
					}
				}
				.text {
					width: 100%;
					padding-bottom: 8px;
					@media screen(md) {
						padding-bottom: 0;
						max-width: 100%;
					}
					@media screen(lg) {
						max-width: 445px;
					}
					span {
						display: block;
						width: 100%;
						font-family: 'Roboto';
						font-style: normal;
						font-weight: 600;
						color: #333333;
						font-size: 14px;
						line-height: 20px;
						br {
							display: none;
						}
						@media screen(md) {
							width: 100%;
							font-size: 16px;
							line-height: 24px;
							br {
								display: block;
							}
							
						}
						@media screen(lg) {
							max-width: 445px;
							br {
								display: none;
							}
						}
					}

				}
			}
			.education__col-buttons {
				display: flex;
				justify-content: flex-end;
				gap: 24px;
				.button {
					img {
						width: 10px;
						height: 16px;
						@media screen(md) {
							height: 16px;
							width: 16px;
						}
					}
					display: flex;
					align-items: center;
					gap: 4px;
					@media screen(md) {
						gap: 8px;
					}
						
					a {
						font-size: 12px;
						line-height: 36px;
						@media screen(md) {
							font-size: 14px;
							line-height: 36px;
						}
						font-family: 'Roboto';
						font-style: normal;
						font-weight: 500;

						/* identical to box height, or 257% */
	
						text-decoration-line: underline;
	
						color: #EA405C;
					}
				}

			}
		}
	}
}