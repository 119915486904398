

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

.modal-container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
/* Modal Content */
.modal-content {
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 320px;
  max-height: 626px;
  overflow: hidden;
  border-radius: 2px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  background: #fff;
  -webkit-box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 19px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen(md) {
    max-width: 450px;
  }
}

.modal-content_container {
  flex: 2;
  max-width: 100%;
  padding: 1rem 3rem;
  padding-bottom: 30px;
}


/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close,
.close-imp {
  color: #626262;
  font-size: 30px;
  font-weight: 300;
  padding: 0 1rem;
  border-radius: 2px;
  transition: 0.4s ease-out;
}

.close:hover,
.close:focus,
.close-imp:hover,
.close-imp:focus {
  color: #111;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  h2 {
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    @media screen(md) {
      font-size: 24px;
    }
  }
}



.modal-success {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  text-align: center;
}
.modal-content_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  max-width: 320px;
  @media screen(md) {
    max-width: 570px;

  }
}

.modal-success-title {
  padding: 0;
  margin: 0;

  h2 {
    padding: 10px 0px 10px;
    margin: 0;
    font-weight: 900;
    font-size: 18px;
    line-height: 30px;
    @media screen(md) {
      font-size: 24px;
      padding: 25px 10px 10px;
    }
  }
}
.modal-success-text {
  margin-bottom: 18px;
  text-align: center;
  span {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #333333;
    @media screen(md) {
      font-size: 18px;
      line-height: 36px;
    }
  }


}
.modal-success-btn {
  cursor: pointer;
  background: #EA405C;
  border-radius: 10px;
  
  width: 100%;
  text-align: center;
  padding: 15px;  
  margin-bottom: 0;
  @media screen(md) {
    margin-bottom: 20px;
  }
  button {
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 900;
    @media screen(md) {
      font-size: 18px;
    }
  }
}